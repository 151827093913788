import * as echarts from "echarts";

const componentChart = () => {
    const observeOptions = {
        root: null,
        threshold: 0.75,
    };

    const bilanzsumme = document.getElementById('bilanzsumme')
    let echartBilanzsumme
    if (bilanzsumme) {
        echartBilanzsumme = echarts.init(bilanzsumme);

        const observeEchartBilanzsumme = new IntersectionObserver(function (entries, observer) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    echartBilanzsumme.setOption({
                        backgroundColor: 'rgba(240,243,227,1)',
                        grid: {
                            top: 40,
                            left: 20,
                            right: 20,
                            bottom: 40,
                        },
                        tooltip: {},
                        xAxis: {
                            data: ['2021', '2022', '2023', '2024e'],
                            axisTick: false,
                            axisLine: {
                                lineStyle: {
                                    width: 0
                                }
                            },
        
                        },
                        yAxis: {
                            show: false,
                            type: 'value',        
                        },
                        series: [
                            {
                                name: 'Bilanzsumme',
                                type: 'bar',
                                barWidth: "40",
                                tooltip: {
                                    valueFormatter: function (value) {
                                        return value + ' Mio. EUR';
                                    }
                                },
                                itemStyle: {
                                    color: 'rgba(162,182,45,1)'
                                },
                                label: {
                                    show: true,
                                    position: 'top',
                       
                                },
                                data: [
                                    '7293.8',
                                    '7054.4',
                                    '6624.4',
                                    {
                                        value: '6723',
                                        itemStyle: {
                                            color: 'rgba(203, 213, 145, 1)'
                                        },
                                        label: {
                                            show: false,
                                        },
                                        tooltip: {
                                            formatter: 'Prognose: + 1 - 2 %'
                                        },
                                    },

                                ],
                            }
                        ]
                    });
                }

                return
            });
        }, observeOptions);

        observeEchartBilanzsumme.observe(bilanzsumme);
    }

    const kundengelder = document.getElementById('kundengelder')
    let echartKundengelder
    if (kundengelder) {
        echartKundengelder = echarts.init(kundengelder);

        const observeEchartKundengelder = new IntersectionObserver(function (entries, observer) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    echartKundengelder.setOption({
                        backgroundColor: 'rgba(240,243,227,1)',
                        grid: {
                            top: 40,
                            left: 20,
                            right: 20,
                            bottom: 40,
                        },
                        tooltip: {},
                        xAxis: {
                            data: ['2021', '2022', '2023', '2024e'],
                            axisTick: false,
                            axisLine: {
                                lineStyle: {
                                    width: 0
                                }
                            },
                        },
                        yAxis: {
                            show: false,
                        },
                        series: [
                            {
                                name: 'Kundengelder',
                                type: 'bar',
                                barWidth: "40",
                                tooltip: {
                                    valueFormatter: function (value) {
                                        return value + ' Mio. EUR';
                                    }
                                },
                                itemStyle: {
                                    color: 'rgba(162,182,45,1)'
                                },
                                label: {
                                    show: true,
                                    position: 'top',
                                },
                                data: [                
                                    '5691.0',
                                    '5590.0',
                                    '4789.6',
                                    {
                                        value: '4933.3',
                                        itemStyle: {
                                            color: 'rgba(203, 213, 145, 1)'
                                        },
                                        label: {
                                            show: false,
                                        },
                                        tooltip: {
                                            formatter: 'Prognose: + 3 %'
                                        },
                                    },

                                ],
                            }
                        ]
                    });
                }

                return
            });
        }, observeOptions);

        observeEchartKundengelder.observe(kundengelder);
    }

    const kreditgeschaeft = document.getElementById('kreditgeschaeft')
    let echartKreditgeschaeft
    if (kreditgeschaeft) {
        echartKreditgeschaeft = echarts.init(kreditgeschaeft);

        const observeEchartKreditgeschaeft = new IntersectionObserver(function (entries, observer) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    echartKreditgeschaeft.setOption({
                        backgroundColor: 'rgba(240,243,227,1)',
                        grid: {
                            top: 40,
                            left: 20,
                            right: 20,
                            bottom: 40,
                        },
                        tooltip: {},
                        xAxis: {
                            data: ['2021', '2022', '2023', '2024e'],
                            axisTick: false,
                            axisLine: {
                                lineStyle: {
                                    width: 0
                                }
                            },
                        },
                        yAxis: {
                            show: false,
                        },
                        series: [
                            {
                                name: 'Kreditgeschäft',
                                type: 'bar',
                                barWidth: "40",
                                tooltip: {
                                    valueFormatter: function (value) {
                                        return value + ' Mio. EUR';
                                    }
                                },
                                itemStyle: {
                                    color: 'rgba(162,182,45,1)'
                                },
                                label: {
                                    show: true,
                                    position: 'top',
                                },
                                data: [                    
                                    '2498.0',
                                    '2832.7',
                                    '3009.6',
                                    {
                                        value: '3250.4',
                                        itemStyle: {
                                            color: 'rgba(203, 213, 145, 1)'
                                        },
                                        label: {
                                            show: false,
                                        },
                                        tooltip: {
                                            formatter: 'Prognose: + 8,0 %'
                                        },
                                    },

                                ],
                            }
                        ]
                    });
                }

                return
            });
        }, observeOptions);

        observeEchartKreditgeschaeft.observe(kreditgeschaeft);
    }

    const kundenwertpapiervolumen = document.getElementById('kundenwertpapiervolumen')
    let echartKundenwertpapiervolumen
    if (kundenwertpapiervolumen) {
        echartKundenwertpapiervolumen = echarts.init(kundenwertpapiervolumen);

        const observeEchartKundenwertpapiervolumen = new IntersectionObserver(function (entries, observer) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    echartKundenwertpapiervolumen.setOption({
                        backgroundColor: 'rgba(240,243,227,1)',
                        grid: {
                            top: 40,
                            left: 20,
                            right: 20,
                            bottom: 40,
                        },
                        tooltip: {},
                        xAxis: {
                            data: ['2021', '2022', '2023', '2024e'],
                            axisTick: false,
                            axisLine: {
                                lineStyle: {
                                    width: 0
                                }
                            },
                        },
                        yAxis: {
                            show: false,
                        },
                        series: [
                            {
                                name: 'Kundenwertpapiervolumen',
                                type: 'bar',
                                barWidth: "40",
                                tooltip: {
                                    valueFormatter: function (value) {
                                        return value + ' Mio. EUR';
                                    }
                                },
                                itemStyle: {
                                    color: 'rgba(162,182,45,1)'
                                },
                                label: {
                                    show: true,
                                    position: 'top',
                                },
                                data: [
                                    '4454.4',
                                    '4238.0',
                                    '5010.1',
                                    {
                                        value: '5310.7',
                                        itemStyle: {
                                            color: 'rgba(203, 213, 145, 1)'
                                        },
                                        label: {
                                            show: false,
                                        },
                                        tooltip: {
                                            formatter: 'Prognose: + 6 %'
                                        },
                                    },

                                ],
                            }
                        ]
                    });
                }

                return
            });
        }, observeOptions);

        observeEchartKundenwertpapiervolumen.observe(kundenwertpapiervolumen);
    }

    window.onresize = function () {
        if (bilanzsumme) {
            echartBilanzsumme.resize();
        }
        if (kundengelder) {
            echartKundengelder.resize();
        }
        if (kreditgeschaeft) {
            echartKreditgeschaeft.resize();
        }
        if (kundenwertpapiervolumen) {
            echartKundenwertpapiervolumen.resize();
        }
    };
};

export default componentChart;
